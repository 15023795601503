<template>
  <header>
    <div class="header">
      <div class="header__top">
        <div class="header__phone">
          <div class="lang">
            <form action="/">
              <select name="language" id="language">
                <option value="1">RU</option>
                <option value="1">KZ</option>
                <option value="1">EN</option>
              </select>

              <select
                name="tenge"
                id="tenge"
                v-model="currency"
                @change="changeCurrency"
              >
                <option value="UA">₸</option>
                <option value="KZ">₸</option>
                <option value="RU">RUB</option>
                <option value="KG">COM</option>
              </select>
            </form>
          </div>
          <a href="tel:+7 (705) 222-32-18">+7 (705) 222-32-18</a>
        </div>

        <div class="header__logo">
          <router-link to="/">
            <img src="@/assets/img/header__logo.svg" alt="/" />
          </router-link>
          <router-link to="/catalog?category=73">
            <img class="sponser-logo" src="@/assets/img/sponser.png" alt="sponser-logo">
          </router-link>
        </div>

        <ul class="header__account">
          <li class="search__block" v-click-outside="hide">
            <transition>
              <input
                type="search"
                class="search"
                placeholder="Поиск товара по каталогу"
                @change="searchProducts()"
                v-show="showSearchBar"
                ref="search"
              />
            </transition>
            <a
              ><img
                src="@/assets/img/icon-search.svg"
                alt="search"
                class="header__icon"
                @click="search"
            /></a>
          </li>
          <li class="wishlist__block">
            <router-link to="/wishlist"
              ><img
                src="@/assets/img/icon-like.svg"
                alt=""
                class="header__icon"
                :class="{ wish: isAuthenticated }"
            /></router-link>
            <div class="wishCounter" v-if="wishLength > 0 && isAuthenticated">
              {{ wishLength }}
            </div>
          </li>
          <li>
            <router-link :to="personal"
              ><img
                src="@/assets/img/icon-account.svg"
                alt=""
                class="header__icon"
                :class="{ auth: isAuthenticated }"
            /></router-link>
          </li>
          <li class="basket">
            <router-link to="/cart"
              ><img
                src="@/assets/img/icon-basket.svg"
                alt=""
                class="header__icon"
            /></router-link>
            <div class="basketQty" v-if="cartLength > 0">
              {{ cartLength }}
            </div>
          </li>
          <div class="userSigned" v-if="isAuthenticated">
            <p>{{ getUserName }}</p>
            <button @click="logout">Выйти</button>
          </div>
        </ul>
      </div>
      <OrderModal :visible="modalShow" @close="closed" :product="product" />
      <div class="header__body">
        <nav class="header__menu">
          <!-- <input type="checkbox" id="menu" class="toggleMenu" ref="menu" />
          <label for="menu" class="toggleMenu"
            ><i class="fa fa-bars"></i
          ></label> -->
          <div
            class="menu-toggle"
            @click="showNavBar"
            :class="{ on: listVisible }"
          >
            <div class="menu-toggle_one"></div>
            <div class="menu-toggle_two"></div>
            <div class="menu-toggle_three"></div>
          </div>
          <ul
            class="header__list"
            @click="listener"
            :class="{ show: listVisible }"
          >
            <li><router-link to="/home">Главная</router-link></li>
            <li><router-link to="/catalog">Каталог товаров</router-link></li>
            <!-- <li><router-link to="/products">Продукция</router-link></li> -->
            <!-- <li><router-link to="/no_page">Акции</router-link></li> -->
            <li><router-link to="/blog">Блог</router-link></li>
            <li><router-link to="/about">о компании</router-link></li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import OrderModal from "@/components/cart/OrderModal.vue";
import ClickOutside from "vue-click-outside";

export default {
  components: {
    OrderModal,
  },
  computed: {
    ...mapGetters("auth", ["getUserName", "isAuthenticated"]),
    ...mapGetters(["getGeo"]),
    ...mapGetters("wishlist", ["wishLength"]),

    cartLength() {
      return this.$store.getters["cart/itemsInCart"];
    },
    personal() {
      if (this.isAuthenticated) {
        return "/personal";
      }
      return "/auth";
    },
  },
  data() {
    return {
      showSearchBar: false,
      modalShow: false,
      product: null,
      paddingLeft: 0,
      listVisible: false,
      currency: null,
    };
  },
  directives: {
    ClickOutside,
  },

  methods: {
    ...mapActions(["setGeolocate"]),
    ...mapActions("auth", ["logout"]),
    ...mapActions("cart", ["updateCart"]),
    ...mapActions("products", ["getProducts"]),

    searchProducts() {
      let input = this.$refs.search;
      if (input.value) {
        this.$router.push({
          path: "/catalog",
          query: { search: input.value },
        });
        input.value = "";
      }
    },
    modalVisibility() {
      this.modalShow = true;
    },
    closed() {
      this.modalShow = false;
    },
    goToMain() {
      this.$router.push("/");
    },
    search() {
      this.showSearchBar = !this.showSearchBar;
    },
    hide() {
      this.showSearchBar = false;
    },
    listener() {
      this.listVisible = false;
      // if (e.target.closest("li")) {
      //   const elem = this.$refs.menu;
      //   elem.checked = false;
      // }
    },
    changeCurrency() {
      this.$store.dispatch("setCurrency", this.currency);
      this.getProducts({ limit: 6 });
      this.updateCart();
    },
    showNavBar() {
      this.listVisible = !this.listVisible;
    },
  },
  created() {
    this.$root.$on("addedToCart", (data) => {
      this.product = {
        ...data,
        image: data.image || data.thumb,
        quantity: +data.status || data.quantity || 1,
      };
      this.modalShow = true;
    });
    this.currency = this.getGeo;
    this.setGeolocate();
  },
};
</script>

<style scoped>
.header {
  position: relative;
  padding: 50px 0;
}

.header__logo {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__logo img {
  width: 100%;
  max-width: 150px;
  height: auto;
  margin: 0 20px;
}

.header__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px 24px 15px;
}
.header__phone {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 0 30%;
}

.header__phone select {
  margin-right: 15px;
}
.header__phone a {
  font-weight: 700;
  color: #606060;
}
/* .header__phone .lang {
  padding: 12px 0 0 10px;
} */

.header__phone .lang select {
  background-color: inherit;
}

.header .header__phone:before {
  content: "";
  background: url("../../assets/img/icon-phone.svg") center/cover no-repeat;
  display: inline-block;
  max-width: 25px;
  max-height: 25px;
  position: absolute;
  left: -40px;
}

.header__account {
  display: flex;
  position: relative;
  justify-content: flex-end;
  flex: 1 0 30%;
  margin: 0;
  padding-right: 50px;
  /* padding: 0 15px 0 0; */
}
.header__account li {
  padding-left: 1rem;
  cursor: pointer;
}

.header__account li:first-child {
  padding: 0;
}

.header__icon {
  width: 100%;
  height: 100%;
}

.header__body {
  border-top: 1px solid #75b051;
  border-bottom: 1px solid #75b051;
  padding-top: 15px;
  padding-bottom: 12px;
}

/* Burger menu */

.header__menu > ul {
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
  justify-content: space-between;
  padding: 0 15px !important;
}

.header__menu > ul li {
  position: relative;
}

.menu-toggle,
.header__menu [type="checkbox"] {
  display: none;
}

/* .header__menu label.toggleMenu {
  display: none;
  cursor: pointer;
  position: relative;
  font-size: 18px;
} */
/* 
.header__menu label.toggleMenu i {
  font-size: 22px;
}

.header__menu label.toggleMenu i.fa::before {
  color: #000000;
} */
.header__menu > ul {
  padding: 0;
}
.header__menu > ul li a {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #2b2b2b;
  transition: 0.3s;
}
.header__menu > ul li a:hover,
.header__menu > ul li a.active {
  color: #75b051;
}

.wishlist__block {
  position: relative;
}

.basket {
  position: relative;
}

.header__account .basketQty,
.header__account .wishCounter {
  display: flex;
  justify-content: center;
  position: absolute;
  top: -15px;
  right: -15px;
  width: 20px;
  height: 20px;
  background: #75b051;
  border-radius: 50%;
  padding: 5px;
  line-height: 10px;
  color: #ffffff;
}

.header__account .userSigned {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -5px;
  padding-left: 30px;
}

.header__account .userSigned p {
  background-color: #75b051;
  border-radius: 15px;
  padding: 0.5rem;
  margin: 0;
  color: #ffffff;
  margin-right: 5px;
}

.header__account .userSigned button {
  background-color: #75b051;
  padding: 0.5rem;
  border-radius: 15px;
  color: #ffffff;
}

.header__account ul ~ li {
  cursor: pointer;
}

.search__block {
  position: relative;
}

.search {
  max-width: 250px;
  border-radius: 20px;
  border: 1px solid black;
  position: absolute;
  top: -5px;
  right: 100%;
  padding: 0.5rem;
  /* animation: all 2s ease-out forwards; */
}

.v-enter-active {
  animation: slide 0.5s ease forwards;
}

.v-leave-to {
  animation: slide 0.5s ease reverse;
}

.menu-toggle.on .menu-toggle_one {
  -moz-transform: rotate(45deg) translate(5px, 4px);
  -ms-transform: rotate(45deg) translate(5px, 4px);
  -webkit-transform: rotate(45deg) translate(5px, 4px);
  transform: rotate(45deg) translate(1px, 4px);
}

.menu-toggle.on .menu-toggle_two {
  opacity: 0;
}

.menu-toggle.on .menu-toggle_three {
  -moz-transform: rotate(-45deg) translate(3px, -6px);
  -ms-transform: rotate(-45deg) translate(3px, -6px);
  -webkit-transform: rotate(-45deg) translate(3px, -6px);
  transform: rotate(-45deg) translate(3px, -6px);
}

.menu-toggle .menu-toggle_one,
.menu-toggle .menu-toggle_three,
.menu-toggle .menu-toggle_two {
  width: 100%;
  height: 3px;
  background: #000;
  margin: 2px auto;
  backface-visibility: hidden;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  border-radius: 20px;
}

@keyframes slide {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@media (max-width: 778px) {
  /* Mobile version of the header */

  .header {
    display: flex;
    align-items: center;
    padding: 25px 15px;
  }

  .header__body {
    flex: 1 1 40%;
    order: 1;
    border-top: none;
    border-bottom: none;
    padding: 0;
  }

  .header__top {
    flex: 1 1 60%;
    align-items: center;
    order: 2;
    padding: 0;
  }

  .header__logo {
    flex: 1 1 30%;
  }
  .header__logo img {
    max-width: 100px;
  }

  .header__account {
    flex: 1 1 30%;
    margin-left: auto;
    margin-right: 0;
    padding-right: 0;
  }

  .auth {
    border: 1px solid #75b051;
    border-radius: 50%;
  }

  /*  */
  .header__menu > ul li {
    padding: 0.5em 1em;
  }
  .header__menu > ul li a {
    font-weight: normal;
  }
  .header__menu > ul.header__list {
    display: block;
    position: absolute;
    box-shadow: 0 2px 6px rgba(117 176 81);
    /* box-shadow: -5px 10px 25px rgba(117 176 81 / 25%); */
    opacity: 0;
    transition: opacity 0.2s linear;
    z-index: 10;
  }

  .search {
    right: 100%;
    z-index: 10;
  }

  /* .menu-toggle,
  .header__menu label.toggleMenu {
    display: block;
  } */
  .menu-toggle {
    width: 20px;
    cursor: pointer;
    display: block;
  }

  /* input.toggleMenu:checked ~ ul.header__list {
    opacity: 1;
    background-color: #ffffff;
    padding: 0;
    margin: 0;
    z-index: 200;
    transition: opacity 0.2s linear;
    border-radius: 10px;
  } */

  .header__menu > ul.header__list.show {
    opacity: 1 ;
    background-color: #ffffff;
    padding: 0;
    margin: 0;
    z-index: 200;
    transition: opacity 0.2s linear;
    border-radius: 10px;
  }

  .header__phone,
  .header__account .userSigned {
    display: none;
  }
}

@media (max-width: 425px) {
  .header__body {
    flex: 1 1 0%;
  }
  .header__top {
    flex: 1 1 65%;
  }
  .header__account li {
    padding-left: 0;
  }
  .header__icon {
    max-width: 80%;
  }
  /* .header__menu label.toggleMenu i {
    font-size: 18px;
  } */
  .menu-toggle {
    width: 16px;
  }
  .header__menu > ul li a {
    font-size: 14px;
  }
  .header__account .wishCounter {
    display: none;
  }
  .wish {
    border-bottom: 1px solid #75b051;
  }
}

@media (max-width: 375px) {
  .header__logo {
    max-width: 170px;
  }
  .search {
    max-width: 200px;
    padding: 0.25rem;
  }
}
</style>
