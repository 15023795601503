import axios from "axios";

// Settings for axios request
const settings = ({ url, method, data }, state) => {
  const SESS_ID = window.$cookies.get("SESSID");

  const header = {
    url,
    method,
    headers: {
      "x-oc-merchant-id": process.env.VUE_APP_MERCHANT_ID,
      "x-oc-merchant-language": "ru-ru",
      "x-oc-session": SESS_ID,
      "x-oc-currency": state.currency
    },
    data,
  };

  return header;
};

export default {
  // Execution when user press button "addToCart",
  // use api "add cart" and simultaneously "get cart" for adding in local storage
  async addToCart({ dispatch, rootState }, payload) {
    // This method put to cart product and quantity
    await axios(
      settings({
        url: "https://back.rizaherb.kz/index.php?route=rest/cart/cart",
        method: "POST",
        data: {
          product_id: payload.product_id,
          quantity: payload.quantity,
        },
      }, rootState)
    );

    // After Post to cart, apply to GET method for getting all products in the cart and then refresh our vuex store
    dispatch("updateCart");
  },

  // Execute when the App started and when use addToCart method, add to store early ordered items

  async updateCart({ commit, rootState }) {
    const reponseData = await axios(
      settings({
        url: "https://back.rizaherb.kz/index.php?route=rest/cart/cart",
        method: "GET",
      }, rootState)
    );
    const {
      data: { data },
    } = reponseData;

    // console.log(data)

    commit("pushToCart", data);
  },

  // Reduce quantity of item, but only in vuex store

  async incrementQty({ commit }, id) {
    commit("increment", id);
    // dispatch("updateCart");
  },

  async decrementQty({ commit }, id) {
    commit("decrement", id);
  },

  async setQty({ commit }, payload) {
    commit("setQtyToCart", payload);
  },

  async changeQty({ dispatch, rootState }, payload) {
    await axios(
      settings({
        url: "https://back.rizaherb.kz/index.php?route=rest/cart/cart",
        method: "PUT",
        data: {
          key: payload.key,
          quantity: payload.quantity,
        },
      }, rootState)
    );

    // After Post to cart, apply to GET method for getting all products in the cart and then refresh our vuex store
    dispatch("updateCart");
  },

  // Remove one product from the cart by the "product key"

  async removeCart({ commit, dispatch, rootState }, key) {
    await axios(
      settings({
        url: `https://back.rizaherb.kz/index.php?route=rest/cart/cart&key=${key}`,
        method: "DELETE",
      }, rootState)
    );
    commit("removeFromCart", key);
    dispatch("updateCart");
  },

  async recountOrder({ state, dispatch, rootState }, payload) {
    try {
      await axios(
        settings({
          url: "https://back.rizaherb.kz/index.php?route=rest/cart/coupon",
          method: "POST",
          data: {
            coupon: payload,
          },
        }, rootState)
      );
      dispatch("updateCart");
    } catch (err) {
      if (err) {
        state.error = "Такого номера купона не существует!";
      }
    }
  },

  clearError({ state }) {
    state.error = null;
  },
};
